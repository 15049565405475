import React from 'react'
import Helmet from 'react-helmet'

import Gallery from '../components/Gallery'
import Layout from '../components/layout'
import MyForm from '../components/form/formspree-form'
import scrollTo from 'gatsby-plugin-smoothscroll'

const HomeIndex = () => {
  const siteTitle = 'Paul Chapman | Web Developer for Hire'
  const siteDescription = 'Charlotte-area developer with expertise in Wordpress development, GatsbyJS and SEO optimization.'

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
      </Helmet>

      <div id="main">
        <section id="one">
          <header className="major">
            <h2>
              What I do.
            </h2>
          </header>
          <p>
            I'm a Web Designer/Developer with 2 decades of experience with web development and management - specializing in GatsbyJS/React, Wordpress development and HTML Newsletters. I'm also experienced with event and pageview tracking with Google Analytics, Ad management with DoubleClick, Search Engine Optimization and QA Testing. Feel free to  <a className="pointer" onClick={() => scrollTo('#contact')}>get in touch</a> to discuss how I can help you and your company.
          </p>
        
        </section>

        <section id="two">
          <h2>Recent Work</h2>

          <Gallery />

          
        </section>

        <section id="contact">
          <h2>Get In Touch</h2>
          <p>
            Ready to start your next project? That's great! Give me a call or send an email and I will get back to you as soon as possible!
            <br/>
          </p>
          <div className="row">
            <div className="8u 12u$(small)">
            <MyForm/>
            </div>
            <div className="4u 12u$(small)">
              <ul className="labeled-icons">
                <li>
                  <h3 className="icon fa-home">
                    <span className="label">Address</span>
                  </h3>
                  Charlotte, NC 28269
                  <br />
                  United States
                </li>
                <li>
                  <h3 className="icon fa-mobile">
                    <span className="label">Phone</span>
                  </h3>
                  <a href="tel:6173192129">617-319-2129</a>
                </li>
                <li>
                  <h3 className="icon fa-envelope-o">
                    <span className="label">Email</span>
                  </h3>
                  <a href="mailto:info@pchpmn.com">info@pchpmn.com</a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default HomeIndex
