import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

const GalleryItem = ({id, source, thumbnail, caption, description, position, toggleLightbox, link, tech}) => {

    const onClick = useCallback((e) => {
        e.preventDefault()
        toggleLightbox(position)
    }, [position, toggleLightbox]);

    return (<article key={id} className="6u 12u$(xsmall) work-item">
    {!link && (
      <a
      className="image fit thumb"
      href={source}
      onClick={onClick}
      >
      <img src={thumbnail} />
      </a>
    )}
    
    {link && (
      <a
      className="image fit thumb"
      href={link}
      target="_blank"
      rel="noreferrer"
      >
      <img src={thumbnail} />
      </a>
    )}
      

        <h3>{caption}</h3>
        <p>{description}</p>
        <p className="work-item__tech"><strong>Using:</strong> {tech}</p>
    </article>)
};

GalleryItem.displayName = 'GalleryItem'
GalleryItem.propTypes = {
  id: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  toggleLightbox: PropTypes.func.isRequired
}

export default GalleryItem