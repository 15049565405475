import full01 from '../../../assets/images/fulls/ahdc.jpg'
import full02 from '../../../assets/images/fulls/idealcap.jpg'
import full03 from '../../../assets/images/fulls/low.jpg'
import full04 from '../../../assets/images/fulls/243steele.jpg'
import full05 from '../../../assets/images/fulls/ghd.jpg'
import full06 from '../../../assets/images/fulls/thekaty.jpg'
import thumb02 from '../../../assets/images/thumbs/idealcap.jpg'
import thumb01 from '../../../assets/images/fulls/ahdc.jpg'
import thumb03 from '../../../assets/images/thumbs/low.jpg'
import thumb04 from '../../../assets/images/thumbs/243steele.jpg'
import thumb05 from '../../../assets/images/thumbs/ghd.jpg'
import thumb06 from '../../../assets/images//fulls/thekaty.jpg'


export const DEFAULT_IMAGES = [
    {
      id: '1',
      source: full01 ,
      thumbnail: thumb01,
      caption: 'AHDC',
      description: 'An affordable housing development firm needed a new look and branding.',
      tech: 'React, GatsbyJS, SASS, AnimateJS, Headless Wordpress',
      url: 'https://ahdcinc.com'
    },
    {
      id: '2',
      source: full02,
      thumbnail: thumb02,
      caption: 'Ideal Capital Group',
      description: 'A family-owned investment firm with decades experience wanted a re-brand.',
      tech: 'React, GatsbyJS, SASS, React Spring, Headless Wordpress',
      url: 'http://idealcapgroup.com/'
    },
    {
      id: '3',
      source: full03,
      thumbnail: thumb03,
      caption: 'Lofts On Washington',
      description: 'Website for a new property from a local real estate company.',
      tech: 'Wordpress, HTML, Gulp, SASS, JS, Timber',
      url: 'https://loftsonwashington.com'
    },
    {
      id: '4',
      source: full04,
      thumbnail: thumb04,
      caption: '243 Steele Rd',
      description: 'Website for a new property in a CT company\'s portfolio.',
      tech: 'Wordpress, HTML, Gulp, SASS, JS, Timber',
      url: 'http://www.243steeleroad.com/'
    },
    {
      id: '5',
      source: full05,
      thumbnail: thumb05,
      caption: 'Galactic Hot Dogs',
      description: 'A book microsite for Fubrain.com.',
      tech: 'HTML, Gulp, SASS, JS, PHP',
      url: 'https://pchpmn.com/portfolio/galactichotdogs/index.php'
    },
    {
      id: '6',
      source: full06,
      thumbnail: thumb06,
      caption: 'HTML Newsletter',
      description: 'A property newsletter for a Texas client.',
      tech: 'MJML, HTML, Gulp, SASS',
      url: 'https://pchpmn.com/portfolio/newsletter/thekaty.html'
    }
]